<script>
import findClosestIndex from "find-closest";

export default {
  name: "WindCard",
  props: ["log"],
  data() {
    return {
      radius: 50,
    };
  },
  computed: {
    windDirectionFI() {
      var wd_names = new Map();
      wd_names.set(0, "pohjoisesta");
      wd_names.set(45, "koillisesta");
      wd_names.set(90, "idästä");
      wd_names.set(135, "kaakosta");
      wd_names.set(180, "etelästä");
      wd_names.set(225, "lounaasta");
      wd_names.set(270, "lännestä");
      wd_names.set(315, "luoteesta");

      var wd_name_index = Array.from(wd_names.keys());

      // https://fi.wikipedia.org/wiki/Ilmansuunta
      return wd_names.get(findClosestIndex(wd_name_index, this.log.wd2minavg));
    },
    windDirection() {
      return {
        X: Math.sin(this.convertedAngle()) * this.radius,
        Y: Math.cos(this.convertedAngle()) * this.radius,
      };
    },
    strokeWidth() {
      return this.radius * 0.2;
    },
    innerRadius() {
      return this.radius - this.strokeWidth / 2;
    },
  },
  methods: {
    convertedAngle() {
      // $ang = abs($tuulensuunta);
      // $angle = deg2rad($ang);
      var ang = Math.abs(this.log.wd2minavg);
      var angle = ang * (Math.PI / 180);
      return angle;
    },
  },
};
</script>

<template>
  <div class="col">
    <div class="card">
      <div class="card-body placeholder-glow">
        <h5 class="card-title">Tuuli</h5>
        <svg v-bind:height="radius * 2" v-bind:width="radius * 2">
          <circle class="gauge_base" v-bind:cx="radius" v-bind:cy="radius" fill="transparent" v-bind:r="innerRadius"
            stroke="gray" v-bind:stroke-width="strokeWidth" />
          <line v-if="log.datetime" v-bind:x1="radius" v-bind:y1="radius" v-bind:x2="radius + windDirection.X"
            v-bind:y2="radius - windDirection.Y" stroke="red" v-bind:stroke-width="strokeWidth / 2" />
          <line v-if="log.datetime" v-bind:x1="radius" v-bind:y1="radius" v-bind:x2="radius - windDirection.X"
            v-bind:y2="radius + windDirection.Y" stroke="blue" v-bind:stroke-width="strokeWidth / 2" />
        </svg>
        <div class="card-text">
          <div :class="{ placeholder: !log.datetime }">
            <span class="bi-compass" /> Tuulen suunta:
            <span v-if="log.datetime">{{ windDirectionFI }} {{ log.wd2minavg }} </span>
            <span v-if="!log.datetime">ei-mistään 0.0</span>
            &deg;
          </div>
          <div :class="{ placeholder: !log.datetime }">
            <span class="bi-wind" /> Tuulen nopeus:
            <span v-if="log.datetime">{{ log.ws2minavg }}</span>
            <span v-if="!log.datetime">0.0</span>
            m/s
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
